import { type MetaFunction, type LinksFunction } from '@remix-run/node'

import { Link } from '@remix-run/react'
import { Col, Container, Row } from 'reactstrap'
import Footer from '#app/components/landing-page/Footer.tsx'
import Hero from '#app/components/landing-page/Hero.tsx'
import SectionContactForm from '#app/components/landing-page/SectionContactForm.tsx'
import SectionFive from '#app/components/landing-page/SectionFive.tsx'
import SectionFour from '#app/components/landing-page/SectionFour.tsx'
import SectionThree from '#app/components/landing-page/SectionThree.tsx'
import SectionTwo from '#app/components/landing-page/SectionTwo.tsx'
import { metaTitleAndDescription } from '#app/helpers/metaTags.helper.ts'

export const meta: MetaFunction = () => {
  return metaTitleAndDescription({
    title: 'Clube do Tennis',
    description: 'Clube do Tennis - O local onde os tenistas se encontram',
    content_type: 'website',
  })
}

export const links: LinksFunction = () => {
  return [
    { rel: 'manifest', href: '/site.webmanifest' },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/favicons/android-chrome-192x192.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '512x512',
      href: '/favicons/android-chrome-512x512.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.ico',
    },
  ]
}

export default function Index() {
  return (
    <div className="overflow-x-hidden vw-100">
      <a href="https://wa.me/+5511939035718" target="_blank" rel="noreferrer">
        <img
          src="../images/lp/wa-whatsapp-icon.svg"
          className="whats-float-icon"
          alt="whatsapp icon"
        />
      </a>
      <Hero />
      <Container className="my-5" id="como-funciona">
        <Row>
          <Col
            className="text-center"
            md={{
              size: 8,
              offset: 2,
            }}
          >
            <h1 className="fw-bolder display-4">
              Seja um membro do Clube do Tennis
            </h1>
            <p className="fs-3 mx-auto mb-0">
              Participe de desafios, pontue no Ranking CdT, desbloqueie
              conquistas e tenha acesso a produtos com descontos exclusivos.
            </p>

            <iframe
              className="rounded-3 my-4 w-100"
              style={{ aspectRatio: '16 / 9' }}
              src="https://www.youtube.com/embed/XY1-hOv8Zi0"
              title="Conheça o Clube do Tennis"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>

            <p className="fs-3">
              E o melhor: tudo isso de{' '}
              <b className="fw-bold text-decoration-underline">
                forma gratuita!
              </b>
            </p>

            <Link
              to="/cadastre-se"
              className="d-inline-flex justify-content-center align-items-center btn btn-primary btn-lg fs-3"
            >
              CRIE SUA CONTA
              <i className="bx bx-chevron-right" style={{ fontSize: 24 }} />
            </Link>
          </Col>
        </Row>
      </Container>
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionContactForm />
      <Footer />
    </div>
  )
}
