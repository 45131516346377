import { getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useFetcher } from '@remix-run/react'
import { useEffect, useRef, useState } from 'react'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap'
import { z } from 'zod'
import { type action } from '#app/routes/fale-conosco.ts'
import { InputMask } from '../forms/InputMask.tsx'
import { phoneMask } from '../forms/masks.ts'
import { SubmitButton } from '../ui/submitButton.tsx'
import { Textarea } from '../ui/textarea.tsx'

export const ContactMessageSchema = z.object({
  name: z.string().min(1, { message: 'Nome obrigatório' }),
  email: z
    .string()
    .min(1, { message: 'Email obrigatório' })
    .email({ message: 'Email inválido' }),
  phone: z.string().min(1, { message: 'Celular obrigatório' }),
  message: z.string().min(1, { message: 'Mensagem obrigatória' }),
  captcha: z.string().optional(),
})

export default function SectionContactForm() {
  const actionFetcher = useFetcher<typeof action>()
  const submitedForm =
    actionFetcher.data && actionFetcher.data.status === 'success'
  const formRef = useRef<HTMLFormElement>(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (submitedForm) {
      setModal(true)
      formRef.current?.reset()
    }
  }, [submitedForm])

  setTimeout(() => {
    setModal(false)
  }, 5000)

  const [form, fields] = useForm({
    id: 'contact-form',
    constraint: getZodConstraint(ContactMessageSchema),
    shouldRevalidate: 'onSubmit',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: ContactMessageSchema })
    },
  })

  return (
    <Container className="section-contact-form" id="contato">
      <Row>
        <Col md="7">
          <img
            src="../images/lp/banner-02-clube-do-tennis.png"
            alt="banner clube do tennis"
            className="img-fluid rounded"
          />
        </Col>
        <Col md="5" className="text-area">
          <h3>Fale conosco</h3>
          <actionFetcher.Form
            method="POST"
            action={'/fale-conosco'}
            ref={formRef}
            id={form.id}
            onSubmit={form.onSubmit}
          >
            <FormGroup>
              <Input
                {...getInputProps(fields.name, { type: 'text' })}
                placeholder="Digite seu nome"
                autoComplete="name"
              />
            </FormGroup>
            <FormGroup>
              <Input
                {...getInputProps(fields.email, { type: 'email' })}
                placeholder="Digite seu email"
                autoComplete="email"
              />
            </FormGroup>
            <FormGroup>
              <InputMask
                {...getInputProps(fields.phone, { type: 'tel' })}
                placeholder="(00) 00000-0000"
                autoComplete="tel"
                mask={phoneMask}
              />
            </FormGroup>
            <FormGroup>
              <Textarea
                {...getInputProps(fields.message, { type: 'text' })}
                placeholder="Digite sua mensagem"
                rows={6}
              />
            </FormGroup>
            <SubmitButton color="primary" className="btn-lg" title="Enviar" />
          </actionFetcher.Form>
        </Col>
      </Row>
      <ThankYouModal onClose={() => setModal(false)} isOpen={modal} />
    </Container>
  )
}
export function ThankYouModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader className="border-bottom-0" toggle={onClose} />
      <ModalBody className="text-center">
        <div className="avatar-md mx-auto mb-4">
          <div className="avatar-title bg-light rounded-circle text-primary h1">
            <i className="mdi mdi-email-open" />
          </div>
        </div>
        <h5>Obrigado por sua mensagem! Em breve entraremos em contato.</h5>
        <Button
          color="primary"
          className="mt-4 fs-5"
          onClick={onClose}
          children="Fechar"
        />
      </ModalBody>
    </Modal>
  )
}
